.bastion-connect {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: auto;
}

    .bastion-connect h1 {
        color: #333;
        text-align: center;
        margin-bottom: 20px;
    }

.tasks, .instructions {
    margin-bottom: 20px;
}

    .tasks h2, .instructions h2 {
        color: #2c3e50;
        border-bottom: 2px solid #2c3e50;
        padding-bottom: 5px;
        margin-bottom: 10px;
    }

    .tasks p, .instructions p {
        margin: 10px 0;
        line-height: 1.6;
    }

.instruction-images {
    display: block;
    margin-top: 20px;
}

    .instruction-images div {
        text-align: center;
        margin-bottom: 20px;
    }

    .instruction-images img {
        width: 100%;
        height: auto;
        border: 1px solid #ccc;
        padding: 10px;
        background: #fff;
    }

button {
    background-color: #001f3f;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    margin: 20px auto;
}

    button:hover {
        background-color: #00122e;
    }

    button:focus {
        outline: none;
    }
