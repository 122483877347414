/* Home.css */
:root {
    --primary-color: #001f3f; /* Navy Blue */
    --secondary-color: #ffffff; /* White */
    --link-color: #007bff; /* Link Color */
    --link-hover-color: #0056b3; /* Link Hover Color */
    --font-family: Arial, sans-serif;
}

body {
    font-family: var(--font-family);
    background-color: var(--secondary-color);
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--secondary-color);
}

    .home-container h2 {
        color: var(--primary-color);
        margin-bottom: 20px;
    }

.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    text-align: center;
}

    .home-content p {
        margin: 0 0 10px;
        font-size: 16px;
    }

.home-link {
    margin-top: 20px;
}

    .home-link a {
        text-decoration: none;
        color: var(--link-color);
        font-size: 16px;
    }

        .home-link a:hover {
            text-decoration: underline;
            color: var(--link-hover-color);
        }
.critical-tasks-link {
    font-size: 1.5em; /* Make the text bigger */
    font-weight: bold; /* Make the text bold */
    color: #001f3f; /* Navy blue text color */
    background-color: #f8f8f8; /* Light grey background */
    padding: 10px;
    border: 2px solid #001f3f; /* Navy blue border */
    border-radius: 5px;
    text-decoration: none; /* Remove underline */
    transition: transform 0.3s, background-color 0.3s; /* Add transition effects */
}

    .critical-tasks-link:hover {
        background-color: #001f3f; /* Change background on hover */
        color: #f8f8f8; /* Change text color on hover */
        transform: scale(1.1); /* Slightly enlarge on hover */
    }
