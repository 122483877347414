/* src/Styling/AssignRoleButton.css */
.assign-role-container {
    text-align: center;
    margin-top: 20px;
}

    .assign-role-container input {
        padding: 10px;
        margin: 10px;
        width: 300px;
    }

    .assign-role-container button {
        padding: 10px 20px;
        cursor: pointer;
    }

.message {
    margin-top: 20px;
    font-weight: bold;
}
.popup {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #0056b3;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

