.project-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: 'Arial', sans-serif;
}

.project-title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.project-description {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
    text-align: justify;
}

.focus-areas-title,
.tools-title,
.languages-title {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}

.knowledge-list {
    list-style-type: disc;
    margin-left: 20px;
    color: #555;
}

.tools-list,
.languages-list {
    font-size: 1.1em;
    color: #555;
    margin-left: 20px;
    text-align: justify;
}

.project-summary {
    font-size: 1.1em;
    color: #333;
    margin-top: 20px;
    text-align: center;
}

.project-image {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 20px auto;
    border-radius: 8px;
}
