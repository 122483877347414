
:root {
    --primary-color: #001f3f; /* Navy Blue */
    --secondary-color: #ffffff; /* White for task list background */
    --hover-color: #D0CCD0; /* Complementary color (Soft Lilac) for hover effect */
    --text-color: #ffffff; /* White for text */
    --completed-text-color: #9e9e9e; /* Light Grey for completed tasks */
    --font-family: 'Lato', sans-serif; /* Modern and clean font */
}

/* Importing the font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
    font-family: var(--font-family);
    background-color: var(--primary-color); /* Navy Blue for the whole page */
    color: var(--text-color); /* White for text */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.task-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #002f5f; /* Slightly different shade of Navy Blue for task container */
    border: 2px solid var(--secondary-color); /* White border */
    border-radius: 10px; /* Rounded corners for container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.task-title {
    font-family: 'Lobster', cursive; /* Fancy and readable font for the title */
    font-size: 2.5em;
    color: var(--primary-color); /* Navy Blue for title text */
    text-shadow: 2px 2px #cccccc; /* Soft shadow for a fancy effect */
    margin-bottom: 20px;
}

.task-list {
    list-style-type: none;
    padding: 0;
    width: 100%;
    background-color: var(--secondary-color); /* White background for task list */
    border-radius: 10px; /* Rounded corners for task list */
    padding: 10px;
}

.task-item {
    font-family: 'Lato', sans-serif; /* Readable font for task items */
    font-size: 1.2em;
    font-weight: bold; /* Make the task text bold */
    display: block; /* Display tasks as block elements */
    color: var(--primary-color); /* Navy Blue font color for task items */
    margin: 10px 0;
    padding: 10px;
    border: 2px solid var(--primary-color); /* Navy Blue border */
    border-radius: 10px;
    background-color: var(--secondary-color); /* White background for task items */
    transition: transform 0.3s, background-color 0.3s;
}

    .task-item.completed {
        text-decoration: line-through;
        color: var(--completed-text-color); /* Light Grey for completed tasks */
    }

    .task-item:hover {
        transform: scale(1.05);
        background-color: var(--hover-color); /* Complementary color (Soft Lilac) for hover effect */
    }








